import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "react-bootstrap"

// import fastForwardLogo from "../images/fast-forward.svg"

const IndexPage = () => (
  <Layout>
    <Seo title="Our Services" />

    <div className="masthead mb-5">
      <Container className="singleCol text-center">
        <h1>Our Services</h1>
        
      </Container>
    </div>
    
    <Container>
      <a href="https://buy.stripe.com/6oEg2weGhgwC2sM7ss">Purchase & Sign-up</a>
    </Container>
    
  </Layout>
)

export default IndexPage
